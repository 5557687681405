<template>
	<div class="overall">
		<!-- 上半部分 -->
		<el-card class="chart-card">
			<!-- 上半部分右边内容 -->
			<div class="plane">

				<!-- 搜索框 -->
				<div>
					<div class="grabble">
						<div style="width: 90%;display: flex;">
							<!-- 姓名 -->
							<el-input class="hunt" v-model="designation" placeholder="请输入会员姓名" size="large"
								@input="orangutan" clearable />
							<!-- 编码 -->
							<el-input class="hunt" v-model="serial" placeholder="请输入会员编码" size="large" @input="howl"
								clearable />
							<!-- 会员组织名称搜索 -->
							<el-select class="hunt" v-model="ability" placeholder="请选择组织名称" size="large"
								@click="multiple" clearable @change="numerical" @focus="closeIt" ref="selectIt" />
							<!-- 是否使用 -->
							<el-select class="hunt" v-model="values" placeholder="请选择是否使用" clearable @clear="empty"
								size="large">
								<el-option label="未使用" value="1" style="width:100%; text-align-last: center;"></el-option>
								<el-option label="使用" value="2" style="width:100%; text-align-last: center;"></el-option>
							</el-select>
						</div>
					</div>
					<div class="grabble3">
						 <div style="display: flex;align-items: center;">
							 
						<!-- 是否过期 -->
						<el-select class="hunt" v-model="values1" placeholder="请选择是否过期" clearable @clear="empty"
							size="large">
							<el-option label="正常" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="过期" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
						<!-- 派发时间 -->
						<el-date-picker v-model="value1" type="daterange" range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间" size="large" @change="immediUpdate" />
						<!-- 搜索 -->
						<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
							:loading='formative'><el-icon class="cancelgu">
								<Search />
							</el-icon>搜索</el-button>
							</div>
					</div>
					<div class="grabble">
						<div class="statistics">
							统计：{{zong}}条
						</div>
						
					</div>
					<div class="grabble1">
						<div class="statistics">
							
						</div>
						<!-- 积分派发 -->
						<div class="plane">
							<div class="distribute">
								<el-upload v-model:file-list="fileList" class="upload-demo" action :auto-upload="true"
									:show-file-list="false" name="excelFile" accept=".xlsx,.xls" limit='20'
									:on-error="error" :http-request="uploadHttpRequest" ref='pleased'
									:disabled='jinyong'>
									<el-button :type="batch==1?'primary':'info'" :loading="acting"
										:disabled='jinyong'>{{duty}}</el-button>
								</el-upload>
								<!-- <el-button type="primary" size="default" :loading='whirl'>批量派发</el-button> -->
								<el-button type="warning" size="default" @click="download"
									:loading='whirl'>下载发放模板</el-button>
								<!-- 发放失败日志 -->
								<el-button style="margin-left: 12px;" type="primary" @command="handleCommand"
									size="default" :hide-on-click="around" :loading="ConfirmLoading" 
									@click="append1">
									发放失败日志
								</el-button>
								<!-- 导出 -->
								<el-button style="margin-left: 12px;" type="primary" @command="handleCommand"
									size="default" :hide-on-click="around" :loading="ConfirmLoading"
									@click="append">
									<img src="../../assets/daochu.png" style="width:14px;margin-right: 6px" alt="">
									导出
								</el-button>
							</div>
						</div>
					</div>
				</div>
				<!-- 表格 -->
				<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain"
					border :header-cell-style="{ background: '#F5F7FA' }" :empty-text="texts" :key="1">
					<!-- 券名称 -->
					<el-table-column align="center" label="券名称" fixed prop="couponName" :show-overflow-tooltip='true'
						width="200" />
					<!-- 会员姓名 -->
					<el-table-column align="center" label="会员姓名" prop="name" :show-overflow-tooltip='true'
						width="150" />
					<!-- 会员编号 -->
					<el-table-column label="会员编码" prop="adminUsername" align="center" :show-overflow-tooltip='true'
						width="150">
					</el-table-column>
					<!-- 所属工会 -->
					<el-table-column label="所属工会" prop="unionName" align="center" :formatter="quantum"
						:show-overflow-tooltip='true' width="250">
					</el-table-column>
					<!-- 所属部门 -->
					<el-table-column label="所属部门" prop="departmentName" align="center" :show-overflow-tooltip='true'
						width="150">
					</el-table-column>
					<!-- 券额度 -->
					<el-table-column label="券额度" prop="cash" align="center" :show-overflow-tooltip='true' width="150">
					</el-table-column>
					<!-- 发放时间  -->
					<el-table-column label="发放时间 " prop="createTime" align="center" :formatter="reach"
						:show-overflow-tooltip='true' width="180">
					</el-table-column>
					<!-- 到期时间  -->
					<el-table-column label="到期时间 " prop="expirationTime" align="center" :formatter="reach"
						:show-overflow-tooltip='true' width="180">
					</el-table-column>
					<!-- 是否过期 -->
					<el-table-column label="是否过期" prop="isInvalid" align="center" :show-overflow-tooltip='true'
						:formatter="formatSex1" width="150">
					</el-table-column>
					<!-- 是否使用 -->
					<el-table-column label="是否使用" prop="state" align="center" :show-overflow-tooltip='true'
						:formatter="formatSex2" width="150">
					</el-table-column>
					<!-- 使用时间  -->
					<el-table-column label="使用时间 " prop="useTime" align="center" :formatter="reach1"
						:show-overflow-tooltip='true' width="180">
					</el-table-column>
					<!-- 销售姓名 -->
					<el-table-column label="销售姓名" prop="salesName" align="center" :formatter="quantum"
						:show-overflow-tooltip='true' width="150">
					</el-table-column>
					<!-- 销售签名 -->
					<el-table-column label="销售签名" prop="signImageInfo" width="350" align="center">
						<template #default="scope">
							<el-image class="table-td-thumb" :src="scope.row.signImageInfo"
								:preview-src-list="[scope.row.signImageInfo]" preview-teleported="true">
							</el-image>
						</template>
					</el-table-column>
					<!-- 备注 -->
					<el-table-column label="备注" prop="reason" align="center" :formatter="quantum"
						:show-overflow-tooltip='true' width="200">
					</el-table-column>
				</el-table>
				<!-- 表格分页 -->
				<!-- 一页时候隐藏 hide-on-single-page="true" -->
				<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
					:current-page="currentPage" :page-size="pagesize" :total="zong" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" />
			</div>
			<!-- <div class="floating" @click="ocean">
        <img class="images" src="../../assets/xiazai.png" alt="">
      </div> -->
		</el-card>
		<!-- 所属组织弹窗 -->
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
		<!-- 导出提示弹窗 -->
		<div>
			<s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']" @yes="yes">
				<div class="task">
					{{quoth}}
				</div>
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import {
		WalletFilled,
		Van,
		ShoppingCartFull,
		Search,
		Download
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onDeactivated,
		onBeforeUnmount
	} from 'vue';
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		PFintegral,
		ZClist,
		managementList,
		managementDownload,
		managementIn,
		PFtotal,
		XZall,
		RWall,
		managementOut
	} from "../../utils/api";
	import {
		useStore
	} from "vuex";
	import qs from 'qs';
	import moments from 'moment'
	// import moments from 'moment'
	export default {
		name: 'couponManagement',
		computed: {},
		setup() {
			const route = useRoute();
			// 批量派发
			const batch = ref(1)
			const duty = ref('批量发放')
			const verywhere = ref(0)

			//判断是否过期
			let formatSex1 = (row, column, isInvalid) => {
				if (isInvalid == 1) {
					return "正常 ";
				} else if (isInvalid == 2) {
					return "过期";
				}
			};
			//判断是否使用
			let formatSex2 = (row, column, state) => {
				if (state == 1) {
					return "未使用 ";
				} else if (state == 2) {
					return "使用 ";
				}
			};
			// 下载提示弹窗
			let anew = ref(false)

			let anew1 = ref(false)
			// 搜索加载
			const formative = ref(false)
			// 导出加载
			let ConfirmLoading = ref(false)

			// 导出按钮
			let append = () => {
				jinyong.value = true
				ConfirmLoading.value = true
				let data = {
					name: designation.value,
					// 会员编码
					username: serial.value,
					// 工会code
					unionCode: Hcode.value,
					//活动id
					doingPracticalThingsId: sessionStorage.getItem('thingsIds'),
					// 开始时间 结束时间
					startTime: a.value,
					endTime: out.value,
					//是否使用
					state: values.value,
					//是否过期
					isInvalid: values1.value,

				}
				console.log(data)
				managementOut(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
						jinyong.value = false
						ConfirmLoading.value = false
						// window.open(res.data.data.excelUrl)
					} else {
						// anew.value = false
						jinyong.value = false
						ConfirmLoading.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}

			// 下拉点击加载
			const wrought = ref(false)
			// 弹窗提示内容
			let quoth = ref('')
			let quoth1 = ref('')


			// 派发积分明细
			const detail = () => {
				let data = {
					// 开始时间 结束时间
					startTime: a.value,
					endTime: out.value,
					// 会员姓名
					name: designation.value,
					// 会员编码
					username: serial.value,
					// 工会code
					unionCode: Hcode.value
				}
				PFdetail(qs.stringify(data)).then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
					} else {
						anew.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}
			// 派发积分合计
			const payout = () => {
				let data = {
					// 开始时间 结束时间
					startTime: a.value,
					endTime: out.value,
					// 会员姓名
					name: designation.value,
					// 会员编码
					username: serial.value,
					// 工会code
					unionCode: Hcode.value
				}
				PFtotal(qs.stringify(data)).then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						anew.value = true
						quoth.value = '任务已提交，请在右侧查看进度'
					} else {
						anew.value = false
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
				console.log(data)
			}
			//积分日志下拉选择
			const pointsLog = (command) => {
				// console.log(command)
				if (command == 'failurePoints') {
					lose()
				} else if (command == 'purchasePoints') {
					goumai()
				} else if (command == 'returnPoints') {
					fanhuan()
				}
			}
			//积分管理下拉选择
			const pointsManagement = (command) => {
				// console.log(command)
				if (command == 'pointsRecovery') {
					withdraw()
				} else if (command == 'pointDeduction') {
					deduct()
				} else if (command == 'pointsExpired') {
					exceed()
				}
			}
			// 导出下拉按钮选择
			const handleCommand = (command) => {
				// console.log(command)
				if (command == 'personage') {
					payout()
				} else if (command == 'personage1') {
					detail()
				} else if (command == 'personage2') {
					passage()
				} else if (command == 'personage3') {
					barter()
				}
			}
			// 下拉按钮动态收回
			const around = ref(false)




			// 积分扣减跳转按钮
			const deduct = () => {
				router.push('/JFdeduct')
			}
			// 积分过期按钮跳转
			const exceed = () => {
				router.push('/JFexceed')
			}
			// 积分撤回跳转按钮
			const withdraw = () => {
				router.push('/JFrecall')
			}
			// 加载中
			const acting = ref(false)
			// 按钮禁用
			const jinyong = ref(false)
			// 工会清空
			const numerical = (e) => {
				if (e == '') {
					Hcode.value = ''
				}
			}

			// 获取任务进度列表
			const schedule = ref([])
			// 获取任务进度状态
			const condition = ref(null)

			// 获取任务进度
			const missions = () => {
				RWall().then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						schedule.value = res.data.data.systemTasksList
						condition.value = schedule.value[0].state
					} else {
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
					}
				})
			}





			// 导入积分派发
			// const error=(e)=>{
			//   console.log(e)
			// }


			const pleased = ref()
			const timer = ref(0)
			const uploadHttpRequest = (data) => {
				// 按钮转圈
				acting.value = true
				duty.value = '请稍等'
				// 按钮禁用
				jinyong.value = true	
				// 按钮颜色改变
				batch.value = 2
				const file = data.file;
				const formData = new FormData()
				formData.append('file', file)
				let datas = {
					doingPracticalThingsId: sessionStorage.getItem('thingsIds'),
					excelFile: formData.get('file')
				}
				managementIn(qs.parse(datas)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						
						anew1.value = true
						quoth1.value = '任务已提交，请在右侧查看进度'
						// 通过vuex传递状态 控制标签页是否能关闭
						tally.value.forEach((item) => {
							// console.log(item)
							if (present.value == item.path) {
								console.log(item)
								item.allow = 2
							}
						})
						// 是否关闭当前页

						// gration()
						// list()
						// ElMessage({
						//     message: "任务已提交，请在右侧查看进度",
						//     type: "success",
						//     center: true,
						// });
						// 定时任务判断按钮状态  window.clearInterval(timer.value);
						timer.value = window.setInterval(function logname() {
							// 获取任务接口
							missions()
							if (condition.value == 1) {
								window.clearInterval(timer.value)
								duty.value = '批量发放'
								batch.value = 1
								jinyong.value = false
								acting.value = false
								ElMessage({
								    message: "批量发放成功",
								    type: "success",
								    center: true,
								});
								list()

								tally.value.forEach((item) => {
									// console.log(item)
									if (present.value == item.path) {
										console.log(item)
										item.allow = 1
									}
								})
							}
						}, 2000);
						pleased.value.clearFiles()
					} else if (res.data.code !== 0) {
						// gration()
						// list()
						ElMessage({
							message: res.data.msg,
							type: "error",
							center: true,
						});
						acting.value = false
					}
				})
			}




			// 发放失败日志跳转
			let append1 = () => {
				router.push('/failureLog')
			}

			// 下载模板
			const download = () => {
				managementDownload().then((res) => {
					console.log(res)
					window.open(res.data.data.excelExampleUrl)
				})
			}






			// 失败积分日志跳转
			const lose = () => {
				router.push('/JFdefeated')
			}


			// 购买积分日志
			const goumai = () => {
				router.push('/JFpurchase')
			}

			//积分返还日志跳转
			const fanhuan = () => {
				router.push('/JFreturn')
			}


			// 数据总共条数
			let zong = ref(null)
			let own = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				}
			}
			//时间格式转换
			let reach = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				}
			}
			let reach1 = (row, colum, cellValue) => {
				if (cellValue != '-') {
					return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
				} else {
					return cellValue
				}
			}
			// 列表数据循环
			const filterTableData = ref([])
			// 表格数据接口
			let list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value,
					//活动id
					doingPracticalThingsId: sessionStorage.getItem('thingsIds')
				}
				managementList(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						zong.value = res.data.data.doingPracticalThingsCouponPage.totalCount
						filterTableData.value = res.data.data.doingPracticalThingsCouponPage.list
						console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 分页获取积分的派发日志
			// 动态加载数据
			let branches = ref(6)
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				console.log(size)
				pagesize.value = size
				branches.value = size
				if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b
					.value !== '' || values.value !== '' || values1.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				console.log(size)
				currentPage.value = size
				texts.value = '加载中'
				if (designation.value !== '' || serial.value !== '' || ability.value !== '' || a.value !== '' || b
					.value !== '' || values.value !== '' || values1.value !== '') {
					search()
				} else {
					list()
				}
			}


			// 加载中
			let texts = ref('加载中')



			let search1 = () => {
				formative.value = true
				search()
			}
			// 搜索框点击
			let search = () => {
				// 时间截止
				// 工会code
				// console.log(Hcode.value)
				// 会员编码框值
				// console.log(serial.value)
				// 姓名
				// console.log(designation.value)
				console.log('开始', a.value)
				console.log('结束', out.value)

				// 搜索接口
				let data = {
					limit: branches.value,
					page: currentPage.value,
					//活动id
					doingPracticalThingsId: sessionStorage.getItem('thingsIds'),
					// 开始时间 结束时间
					startTime: a.value,
					endTime: out.value,
					// 会员姓名
					name: designation.value,
					// 会员编码
					username: serial.value,
					// 工会code
					unionCode: Hcode.value,
					//是否使用
					state: values.value,
					//是否过期
					isInvalid: values1.value,
				}
				managementList(qs.stringify(data)).then((res) => {
					// console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						zong.value = res.data.data.doingPracticalThingsCouponPage.totalCount
						filterTableData.value = res.data.data.doingPracticalThingsCouponPage.list
						// console.log(filterTableData.value)
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}

			let a = ref('')
			let b = ref('')
			let c = ref('')
			let out = ref('')

			let values1 = ref('')
			// 是否启用
			let values = ref('')
			// 时间清空监听
			const immediUpdate = (e) => {
				if (e !== null) {
					a.value = new Date(e[0]).getTime() / 1000
					b.value = new Date(e[1]).getTime() / 1000
					c.value = moments(b.value * 1000).format().substring(0, 10)
					out.value = new Date(c.value + ' ' + '23:59:59').getTime() / 1000
				} else if (e == null) {
					a.value = ''
					b.value = ''
					c.value = ''
					out.value = ''
				}
				console.log(e)
			}

			// 搜索组织列表接口
			// 获取code 
			let Hcode = ref('')
			// 搜索组织选择值
			let ability = ref('')
			// 弹窗树形选择
			let dendrogram = (node) => {
				ability.value = node.unionName
				Hcode.value = node.unionCode
				console.log(node)
			}
			const lang = () => {
				ZClist().then((res) => {
					// console.log(res)
					texture.value = res.data.data.manageUnionList
				})
			}
			// 弹窗组织列表树形
			let texture = ref([])
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 选择组织按钮
			let multiple = () => {
				visible.value = true
			}
			// 搜索组织弹窗
			let visible = ref(false)



			// 会员编码框
			let serial = ref('')
			// 搜索框变化值
			let orangutan = () => {
				// if(designation.value==''&&serial.value==''&&ability.value==''&&achieve.value==''&&asdf.value==''||asdf.value==null){
				//   list()
				//   console.log('空了')
				// }
				console.log('变化了')
			}
			// 姓名输入框值
			let designation = ref('')


			// 总派发积分
			const departmentCount = ref(0)
			// 在途积分
			const userCount = ref(0)
			// 可派发积分
			const kepaifa = ref(0)
			// 已换购积分
			const integralCount = ref(0)




			let selectIt = ref()
			// 下拉隐藏
			let closeIt = () => {
				selectIt.value.blur();
			}


			// 时间选择
			let value1 = ref('')
			const whirl = ref(false)

			onDeactivated(() => {
				// window.clearInterval(timer.value);
				// console.log('离开了')
			})

			onBeforeUnmount(() => {
				// window.clearInterval(timer.value);
				// console.log('离开了123')
			})


			// vuex获取状态
			const store = useStore();
			// 获取当前页path
			const present = ref('')
			// 通过vuex获取标签栏
			const tally = ref([])

			onMounted(() => {
				// 获取标签栏数组
				tally.value = store.state.tagsList
				// 获取当前页path
				present.value = route.path

				// console.log(route.path)
				// console.log(store.state.tagsList)

				lang()
				list()
			})
			// 待办内容点击事件
			const router = useRouter()
			const backlog = (row) => {
				router.push({
					path: '/homes'
				})
				console.log(row.date)
			}
			// 待办内容
			const tableData = [];
			// 头像加载失败
			const errorHandler = () => true;
			return {
				batch,
				duty,
				verywhere,
				// copyreader,
				// condition,
				own,
				quoth1,
				quoth,
				// subscribe,
				// table,
				// 浮窗点击
				// ocean,
				// 预约下载中心弹窗
				// centre,
				// 下载提示弹窗
				// anew1,
				anew,
				// 过期按钮
				exceed,
				search1,
				// 搜索加载
				formative,
				// 导出下拉按钮选择
				wrought,
				handleCommand,
				around,
				//积分日志下拉选择
				pointsLog,
				//积分管理下拉选择
				pointsManagement,
				// 积分扣减
				deduct,
				// 积分撤回             
				withdraw,
				pleased,
				acting,
				uploadHttpRequest,
				// error,
				jinyong,
				numerical,
				goumai,
				// 下载模板
				download,

				immediUpdate,
				reach,
				reach1,
				filterTableData,
				// 可派发积分
				kepaifa,
				// 已换购积分
				integralCount,
				// 在途积分
				userCount,
				// 派发总积分
				departmentCount,
				// 失败积分跳转
				lose,
				// 分页
				zong,
				branches,
				handleCurrentChange,
				handleSizeChange,
				currentPage,
				pagesize,
				// 组织弹窗
				ability,
				texture,
				visible,
				dendrogram,
				defaultProps,
				multiple,
				// 会员编码框
				serial,
				// 搜索框
				search,
				// 姓名框变化值
				orangutan,
				// 会员姓名
				designation,
				// 时间选择
				whirl,
				value1,
				errorHandler,
				tableData,
				backlog,
				texts,
				fanhuan,
				closeIt,
				selectIt,
				values1,
				// 是否启用
				values,
				append,
				append1,
				formatSex1,
				formatSex2,
			};
		},
		components: {
			Search,
			Download,
			WalletFilled,
			Van,
			ShoppingCartFull,
		},
	};
</script>
<style lang='scss' scoped>
	.tishi {
		width: 15px;
		height: 15px;
		margin-right: 5px;
	}

	.trousers {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-top: 15px;
	}

	.minitype {
		font-size: 16px;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.grabble {
		width: 100%;
		margin-top: 15px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}
.grabble1 {
		width: 100%;
		/* margin-top: 15px; */
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}
	.grabble3 {
		width: 80%;
		margin-top: 15px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	.task {
		font-size: 14px;
	}

	.images {
		width: 20px;
		height: 20px;
	}

	.floating {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		right: 0%;
		top: 50%;
		width: 40px;
		height: 40px;
		background-color: #409EFF;
		border-radius: 5px 0 0 5px;
		z-index: 10;
	}

	.loading {
		width: 8px;
		height: 8px;
		border: 2px solid #C9E3FF;
		border-top-color: transparent;
		border-radius: 100%;
		animation: circle infinite 0.75s linear;
		margin-right: 5px;
	}

	// 转转转动画
	@keyframes circle {
		0% {
			transform: rotate(0);
		}

		100% {
			transform: rotate(360deg);
		}
	}





	.guoqi {
		width: 32px;
	}

	::v-deep .el-col-8 {
		max-width: 25.5% !important;
	}

	.limit {
		background-color: #F08080;
		color: #fff;
		--el-button-active-border-color: #F08080;
		--el-button-hover-border-color: #F08080;
	}

	.withhold {
		background-color: #BA55D3;
		color: #fff;
		--el-button-active-border-color: #BA55D3;
		--el-button-hover-border-color: #BA55D3;
	}

	.recall {
		background-color: #FFA07A;
		color: #fff;
		--el-button-active-border-color: #FFA07A;
		--el-button-hover-border-color: #FFA07A;
	}

	::v-deep .el-table__header {
		margin: 0 !important;
	}

	/* 调整表格边距 */
	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.upload-demo {
		margin-right: 10px;
	}

	.el-pagination {
		margin-top: 10px;
	}

	/* ::v-deep .chart-card::-webkit-scrollbar {
		display: block !important;
	} */

	// 派发积分模块
	.payout {
		font-size: 17px;
		font-weight: bold;
		margin-right: 10px;
	}

	.distribute {
		display: flex;
		align-items: center;
	}

	.hunt {
		width: 27%;
		margin-right: 10px;
	}


	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		/* padding-top: 20px; */
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 150px);
		overflow: auto;
		/* padding: 10px; */
	}

	.el-avatar {
		--el-avatar-bg-color: #F5F5F5;
	}

	.statistics {
		width: 30%;
		font-size: 14px;
		color: #909399;
		/* margin-bottom: 15px; */
	}

	// 右半部分下边内容
	.el-table--enable-row-transition .el-table__body td.el-table__cell {
		cursor: pointer;
	}

	.el-checkbox__label {
		padding-left: 25px;
	}

	::v-deep .el-table__body {
		width: 96% !important;
		margin: auto;
		// margin-top: 10px;
		// margin-bottom: 20px;
	}

	.el-table__footer {
		width: 96% !important;
		margin: auto;
		// margin-top: 10px;
		// margin-bottom: 20px;
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.box-card {
		width: 480px;
	}

	// 右半部分上边内容
	.impose2 {
		margin-left: 10px;
	}

	.impose1 {
		margin-left: 10px;
	}

	.crew {
		font-size: 14px;
		color: #999;
	}

	.count1 {
		font-size: 25px;
		font-weight: bold;
		color: rgb(100, 213, 114);
	}

	.count2 {
		font-size: 25px;
		font-weight: bold;
		color: rgb(242, 94, 67);
	}

	.count3 {
		font-size: 25px;
		font-weight: bold;
		color: #A9A9A9;
	}

	.count {
		font-size: 25px;
		font-weight: bold;
		color: rgb(45, 140, 240);
	}

	.magnitude {
		width: 71%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	/* .el-card {
		width: 99%;
	} */

	.amount {
		display: flex;
		flex-direction: row;
	}

	.ritherd {
		width: 98%;
		display: flex;
		flex-direction: row;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.union1 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(100, 213, 114);
	}

	.union2 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(242, 94, 67);
	}

	.union3 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: #A9A9A9;
	}

	.union {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 100px;
		background-color: rgb(45, 140, 240);
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	/* .plane {
		width: 100%;
		// margin-left: 20px;
	} */

	// 左半部分上边内容
	@media screen and (max-width: 1200px) {
		.tiem {
			display: none !important;
		}
	}

	.quantum {
		margin-right: 70px;
	}

	.tiem {
		font-size: 14px;
		color: #999;
		line-height: 25px;
		display: flex;
		align-items: center;
	}

	.inventory {
		width: 40%;
		overflow: hidden;
		border-radius: 4px;
	}

	.username {
		margin-left: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.name {
			font-size: 30px;
			color: #222;
		}

		.super {
			font-size: 14px;
			color: #999;
		}
	}

	.stration {
		padding-bottom: 20px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		border-bottom: 2px solid #ccc;
	}

	.message {
		background-color: #ffffff;
		padding: 20px;
	}

	// 上半部分内容
	// .upside {
	//   display: flex;
	// }
	// 整体
	.overall {
		/* padding-left: 10px;
		padding-top: 10px;
		// margin-top: 30px;
		height: 94%; */
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	::v-deep .el-dialog__body {
		padding: 10px 10px 10px 10px !important;
		height: 215px !important;
		overflow: auto !important;
	}

	::v-deep .el-dialog__body::-webkit-scrollbar {
		display: none;
	}
	::v-deep .el-image__preview {
		cursor: pointer;
		height: 30px;
	}
</style>
<style>

</style>