<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 实事名称 -->
					<el-form-item label="实事名称" prop="title">
						<div class="aaaa">
							<el-input v-model="form.title" placeholder="请填写实事名称" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 操作模式 -->
					<el-form-item label="操作模式" prop="operationMode">
						<el-select v-model="form.operationMode" placeholder="请选择操作模式" size="medium">
							<el-option label="单次服务到店核销或纯展示" value="1"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="多次在线支付购买" value="2"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="手动多次发券到店核销" value="3"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 优惠名称  -->
					<el-form-item label="优惠名称" prop="preferentialTitle" v-if="form.operationMode==1">
						<div class="aaaa">
							<el-input v-model="form.preferentialTitle" placeholder="请填写优惠名称" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 是否发放优惠 -->
					<el-form-item label="是否发放优惠" prop="isPreferential" v-if="form.operationMode==1">
						<el-select v-model="form.isPreferential" placeholder="请选择是否发放优惠" size="medium">
							<el-option label="发放" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="不发放" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 卡片样式图片 -->
					<el-form-item label="卡片样式图片" prop="breviary1" v-if="form.isPreferential==1">
						<el-upload action="/admin/api/v1/doingPracticalThings/saveImage" list-type="picture-card"
							limit="2" ref="qingkong" v-model="form.breviary1" :auto-upload="true" :on-change="change1"
							:on-remove="Remove1" :class="styA1==0?'styB':'styC'" :before-upload="beforeImageUpload1"
							:before-remove="beforeremove1" :on-preview="onpreview1" :headers="header1"
							accept=".jpeg,.png,.jpg,.bmp,.gif" :data='{
					imageFile: imageFile
					}' name="imageFile" :on-success="succ1">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽700px、高420px。)
						</div>
					</el-form-item>
					<!-- 金额 -->
					<el-form-item label="金额" prop="cash" v-if="form.operationMode==2">
						<div class="aaaa">
							<el-input v-model="form.cash" placeholder="请填写金额" size="large" onkeyup="value=value.replace(/[^\d.]/g,'')"></el-input>
						</div>
					</el-form-item>
					<!-- 是否收集手机号 -->
					<el-form-item label="是否收集手机号" prop="isMobile" v-if="form.operationMode==2">
						<el-select v-model="form.isMobile" placeholder="请选择是否收集手机号" size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否预约时间 -->
					<el-form-item label="是否预约时间" prop="isReservationTime" v-if="form.operationMode==2">
						<el-select v-model="form.isReservationTime" placeholder="请选择是否预约时间" size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否收集留言 -->
					<el-form-item label="是否收集留言" prop="isMessage" v-if="form.operationMode==2">
						<el-select v-model="form.isMessage" placeholder="请选择是否收集留言" size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否允许多次购买  -->
					<el-form-item label="是否允许多次购买 " prop="isManyBuy" v-if="form.operationMode==2">
						<el-select v-model="form.isManyBuy" placeholder="请选择是否允许多次购买 " size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 限制购买次数  -->
					<el-form-item label="限制购买次数 " prop="buyNumber" v-if="form.operationMode==2">
						<div class="aaaa">
							<el-input v-model="form.buyNumber" placeholder="请填写限制购买次数" size="large" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
						</div>
					</el-form-item>
					<!-- 是否为套餐模式  -->
					<el-form-item label="是否为套餐模式 " prop="isSetMenu" v-if="form.operationMode==2">
						<el-select v-model="form.isSetMenu" placeholder="请选择是否为套餐模式 " size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否收集销售姓名  -->
					<el-form-item label="是否收集销售姓名 " prop="isSalesName">
						<el-select v-model="form.isSalesName" placeholder="请选择是否收集销售姓名 " size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否收集销售签名 -->
					<el-form-item label="是否收集销售签名" prop="isSign">
						<el-select v-model="form.isSign" placeholder="请选择是否收集销售签名" size="medium">
							<el-option label="是 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否消息推送 -->
					<el-form-item label="是否消息推送" prop="isMessagePush">
						<el-select v-model="form.isMessagePush" placeholder="请选择是否消息推送" size="medium">
							<el-option label="推送 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="不推送" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 手机号列表 -->
					<el-form-item label="手机号列表" prop="messageMobile" v-if="form.isMessagePush==1">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.messageMobile" placeholder="请填写手机号列表"></el-input>
						</div>
						<div class="referrals">
							（提示：多个手机号用英文逗号隔开）
						</div>
					</el-form-item>
					<!-- 活动时间 -->
					<el-form-item label="发放时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetimerange" range-separator="截至"
								start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 所属工会 -->
					<el-form-item label="所属工会" prop="choose">
						<div class="aaaa">
							<el-input v-model="form.choose" readonly placeholder="请选择所属工会"
								@click="visible = true"></el-input>
						</div>
					</el-form-item>
					<!-- 实事类型 -->
					<el-form-item label="实事类型" prop="type">
						<el-select v-model="form.type" placeholder="请选择实事类型" size="medium">
							<el-option label="直属机关工会" value="1"
								style="width:100%; text-align-last: center;"></el-option>
							<el-option label="基层工会" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否发布 -->
					<el-form-item label="是否发布" prop="isEnable">
						<el-select v-model="form.isEnable" placeholder="请选择是否发布" size="medium">
							<el-option label="发布" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="未发布" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 排序 -->
					<el-form-item label="排序" prop="sort">
						<div class="aaaa">
							<el-input v-model="form.sort" type="number" placeholder="请填写排序值" size="large"></el-input>
						</div>
						<div class="referrals">
							（提示：大到小排序 默认100）
						</div>
					</el-form-item>
					<!-- 内页展示主图 -->
					<el-form-item label="内页是否展示主图" prop="isDisplayImage">
						<el-select v-model="form.isDisplayImage" placeholder="请选择内页是否展示主图" size="medium">
							<el-option label="展示 " value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="不展示" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 标题图片 -->
					<el-form-item label="主题图片" prop="breviary">
						<el-upload action="/admin/api/v1/doingPracticalThings/saveImage" list-type="picture-card"
							limit="2" ref="qingkong" v-model="form.breviary" :auto-upload="true" :on-change="change"
							:on-remove="Remove" :class="styA==0?'styB':'styC'" :before-upload="beforeImageUpload"
							:before-remove="beforeremove" :on-preview="onpreview" :headers="header"
							accept=".jpeg,.png,.jpg,.bmp,.gif" :data='{
              imageFile: imageFile
              }' name="imageFile" :on-success="succ">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽700px、高400px。 )
						</div>
					</el-form-item>

					<!-- 编辑器 -->
					<el-form-item prop="copyreader">
						<div class="subject">
							<span class="xingxing">*</span>
							主体内容
							<span class="referrals">( 推荐：视频体积小于100M，图片体积小于1M。 )</span>
						</div>
						<div style="border: 1px solid #ccc; width:100%">
							<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
								:defaultConfig="toolbarConfig" :mode="mode" />

							<Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml"
								:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
			<!-- 所属工会选择 -->
			<div>
				<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
					<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
				</s3-layer>
			</div>
		</el-card>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		Plus,
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		ElMessage
	} from "element-plus";
	import {
		addThings,
		ZClist,
		ssPictures,
	} from "../../utils/api"
	import {
		useRouter,
		useRoute
	} from "vue-router";
	// import { Pictures } from '../../utils/api'
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	export default {
		name: "ssadd",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			const route = useRoute();
			// 清空图片
			const qingkong = ref('')
			let data = ref(null);
			// 按钮加载
			const ConfirmLoading = ref(false)

			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 上传卡片图片
			const header1 = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传主题图片成功地址
			let location = ref('')
			const succ = (res) => {
				location.value = res.data
				console.log(res)
			}
			// 获取上传卡片图片成功地址
			let location1 = ref('')
			const succ1 = (res) => {
				location1.value = res.data
				console.log(res)
			}
			// // 自定义上传
			// const httpreqst = (param) => {
			// 	console.log(param)
			// 	console.log(param.file.name)
			// 	data.value = {
			// 		imageFile: param.file.namefileList,
			// 		imageFile1:param.file.namefileList,
			// 	}
			// 	ssPictures(data).then((res) => {
			// 		console.log(res)
			// 		resolve(res);
			// 	})
			// }
			const styA = ref(0)

			const change = (res, fileList) => {
				console.log(res, fileList)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const styA1 = ref(0)
			const change1 = (res, fileList) => {
				console.log(333333333333, res, fileList)
				styA1.value = fileList.length
				form.breviary1 = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			const beforeImageUpload1 = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除主题图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				styA.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}
			const onpreview1 = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除卡片图片
			const beforeremove1 = (File, fileList1) => {
				console.log(File)
				console.log(fileList1)
				styA1.value = 0
			}
			const Remove1 = (file) => {
				console.log(file)
			}
			// 获取工会列表信息
			const messge = () => {
				ZClist().then((res) => {
					texture.value = res.data.data.manageUnionList
				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),
			]
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 提交 按钮
			const router = useRouter();
			const onSubmit = () => {

				// console.log('标题',form.name)
				// console.log('副标题',form.bywork)
				// console.log('缩略图地址',location1.value)
				// console.log('类型',form.sex)
				// console.log('是否发布',form.are)
				// console.log('上传附件',address.value)
				// console.log('编辑器内容',form.copyreader)
				if (valueHtml.value == '<p><br></p>') {
					form.copyreader = ''
				} else {
					form.copyreader = valueHtml.value
				}
				var b = ref(new Date(form.value1[0]))
				var begin = ref('')
				begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value.getDate() + ' ' +
					b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value.getSeconds();
				var start = ref(new Date(begin.value).getTime() / 1000)
				console.log('开始时间', start.value)
				// 截至时间
				var d = ref(new Date(form.value1[1]))
				var finish = ref('')
				finish.value = d.value.getFullYear() + '-' + (d.value.getMonth() + 1) + '-' + d.value.getDate() + ' ' +
					d.value.getHours() + ':' + d.value.getMinutes() + ':' + d.value.getSeconds();
				var over = ref(new Date(finish.value).getTime() / 1000)
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						// 活动名称
						title: form.title,
						//操作模式
						operationMode: form.operationMode,
						//金额
						cash: form.cash,
						//是否收集手机号 
						isMobile: form.isMobile,
						//是否预约时间
						isReservationTime: form.isReservationTime,
						//是否收集留言 
						isMessage: form.isMessage,
						//是否允许多次购买
						isManyBuy:form.isManyBuy,
						//限制购买次数
						buyNumber:form.buyNumber,
						//是否为套餐模式
						isSetMenu:form.isSetMenu,
						//优惠名称
						preferentialTitle: form.preferentialTitle,
						//是否发放优惠 
						isPreferential: form.isPreferential,
						//卡片图片
						cardImageInfo: location1.value,
						//是否收集销售姓名
						isSalesName:form.isSalesName,
						//是否收集销售签名
						isSign:form.isSign,
						//是否消息推送
						isMessagePush: form.isMessagePush,
						//是否展示内页主图
						isDisplayImage: form.isDisplayImage,
						//排序
						sort: form.sort,
						//手机号列表
						messageMobile: form.messageMobile,
						//选择开始和截止时间
						startTime: start.value,
						endTime: over.value,
						//工会CODE
						unionCode: gaincode.value,
						// 活动类型
						type: form.type,
						//是否发布
						isEnable: form.isEnable,
						// 图片
						imageInfo: location.value,
						// 编辑器内容
						content: form.copyreader,

					}
					if (valid == true) {
						ConfirmLoading.value = true
						addThings(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'doingThings',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')

			// 模拟 ajax 异步获取内容
			onMounted(() => {
				messge()
			})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'uploadImage', // 上传图片
					'delIndent', // 缩进
					'indent', // 增进
					'deleteImage', //删除图片
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
					'uploadVideo',
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			// 富文本上传图片地址
			// let fils=ref('')
			// placeholder: '请输入内容...',
			const editorConfig = {
				MENU_CONF: {}
			}
			// 视频上传
			editorConfig.MENU_CONF['uploadVideo'] = {
				// 上传视频的配置
				// 接口
				server: '/admin/api/v1/doingPracticalThings/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				// 限制上传视频大小
				maxFileSize: 104857600, // 100M
				//上传字段 
				fieldName: 'imageFile',
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 判断视频是否大于100M
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 104857600) {
						return true
					} else if (isLt2M > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError(file, res) {
					console.log(file)
					console.log(res)
					if (file.size > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
					} else {
						ElMessage({
							message: '上传失败，请稍后再试',
							type: "warning",
							center: true,
						});
					}
				},
			}


			// 图片上传
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置 
				// 接口
				server: '/admin/api/v1/doingPracticalThings/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// meta: {
				//   imageFile:fils
				// },
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},
			}

			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})

			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			const visible = ref(false);
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				title: "",
				preferentialTitle: "",
				isPreferential: "",
				value1: "",
				choose: "",
				type: "",
				isEnable: "",
				isDisplayImage: '',
				sort: '',
				copyreader: "",
				breviary: "",
				breviary1: "",
				isMessagePush: "",
				messageMobile: "",
				operationMode: "",
				cash: "",
				isMobile: "",
				isReservationTime: "",
				isMessage: "",
				isManyBuy:"",
				isSetMenu:"",
				buyNumber:"",
				isSalesName:"",
				isSign:"",
			})
			// 验证表单提示
			const rules = {
				title: [{
					required: true,
					message: "请填写实事名称",
					trigger: "blur"
				}, ],
				preferentialTitle: [{
					required: true,
					message: "请填写优惠名称",
					trigger: "blur"
				}, ],
				operationMode: [{
					required: true,
					message: "请选择操作模式",
					trigger: "change"
				}, ],
				isPreferential: [{
					required: true,
					message: "请选择是否发放优惠",
					trigger: "change"
				}, ],
				cash: [{
					required: true,
					message: "请填写金额",
					trigger: "blur"
				}, ],
				isMobile: [{
					required: true,
					message: "请选择是否收集手机号",
					trigger: "change"
				}, ],
				isReservationTime: [{
					required: true,
					message: "请选择是否预约时间",
					trigger: "change"
				}, ],
				isMessage: [{
					required: true,
					message: "请选择是否收集留言",
					trigger: "change"
				}, ],
				isManyBuy: [{
					required: true,
					message: "请选择是否允许多次购买",
					trigger: "change"
				}, ],
				buyNumber: [{
					required: true,
					message: "请填写限制购买次数",
					trigger: "blur"
				}, ],
				isSetMenu: [{
					required: true,
					message: "请选择是否为套餐模式",
					trigger: "change"
				}, ],
				breviary1: [{
					required: true,
					message: "请上传卡片样式图片",
					trigger: "change"
				}, ],
				isSalesName: [{
					required: true,
					message: "请选择是否收集销售姓名",
					trigger: "change"
				}, ],
				isSign: [{
					required: true,
					message: "请选择是否收集销售签名",
					trigger: "change"
				}, ],
				isMessagePush: [{
					required: true,
					message: "请选择是否消息推送",
					trigger: "change"
				}, ],
				messageMobile: [{
					required: true,
					message: "请填写手机号列表",
					trigger: "blur"
				}, ],
				value1: [{
					required: true,
					message: "请选择时间",
					trigger: "change"
				}, ],
				choose: [{
					required: true,
					message: "请选择所属工会",
					trigger: "change"
				}, ],
				type: [{
					required: true,
					message: "请选择实事类型",
					trigger: "change"
				}, ],
				isEnable: [{
					required: true,
					message: "请选择是否发布",
					trigger: "change"
				}, ],
				isDisplayImage: [{
					required: true,
					message: "请选择内页是否展示主图",
					trigger: "change"
				}, ],
				breviary: [{
					required: true,
					message: "请上传主题图片",
					trigger: "change"
				}, ],
				copyreader: [{
					required: true,
					message: "请填写主题内容",
					trigger: "change"
				}, ],
			}
			return {
				qingkong,
				header,
				header1,
				succ,
				succ1,
				data,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				// httpreqst,
				dialogVisible,
				dialogImageUrl,
				onpreview,
				onpreview1,
				beforeremove,
				beforeremove1,
				Remove,
				Remove1,
				styA,
				styA1,
				change,
				change1,
				beforeImageUpload,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules,
				visible,
				texture,
				defaultProps,
				dendrogram,
				defaultTime2,
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 150px !important;
		font-weight: bold;
	}
</style>